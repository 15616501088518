import { ThunkDispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { RootState } from '../../configuration/setup/store';

// Events received from web-workshop
export const TOKEN_RENEWED = 'WEB_WORKSHOP/TOKEN_RENEWED';

const PREFIX = 'NOTIFICATION_SETTINGS/';
export const SHOW_NOTIFICATION = `${PREFIX}SHOW_NOTIFICATION`;
interface MessageEvent<T> {
    type: string;
    payload: T;
}

export const sendMessage = (message: MessageEvent<any>) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const receiveMessage = (event: any, dispatch: ThunkDispatch<Dispatch<any>, RootState, any>) => {
    const actionType = event.data.type;
    if (!actionType) {
        // eslint-disable-next-line no-console
        console.log('Received event in notification-settings: ', event);
        return;
    }
    if (actionType === TOKEN_RENEWED) {
        const { token } = event.data.payload;
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        // eslint-disable-next-line no-console
        console.log('Received event in notification-settings with type not handled: ', actionType);
    }
};

// eslint-disable-next-line react/display-name
const MessageHandler = React.memo(() => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const listenerCallback = (event: any) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
    }, []);

    return null;
});

export default MessageHandler;
